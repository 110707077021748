<template>
  <v-row class="py-2">
    <v-dialog
      v-model="dialog"
      persistent
      max-width="60%"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          class="text-none text-white"
          color="primary"
          depressed
          rounded
          v-bind="attrs"
          v-on="on"
        >
          <v-icon
            dark
            left
          >
            mdi-plus
          </v-icon>
          Adicionar
        </v-btn>
      </template>
      <v-card>
        <v-toolbar
          dark
          color="primary"
        >
          <v-toolbar-title>Cadastro cotação</v-toolbar-title>
          <v-spacer />
        </v-toolbar>
        <base-loading v-if="loading" />
        <v-form
          v-else
          ref="form"
          dense
          lazy-validation
        >
          <v-card-text>
            <v-container>
              <v-row>
                <v-col
                  cols="12"
                  md="6"
                >
                  <v-autocomplete
                    v-model="form.categoria_id"
                    :items="categories"
                    item-text="nome"
                    item-value="id"
                    class="d-flex justify-space-between"
                    label="Categoria do Produto *"
                    :rules="[rules.required]"
                    clearable
                    dense
                    autocomplete="nope"
                    outlined
                    rounded
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="6"
                >
                  <v-autocomplete
                    v-model="form.produto_id"
                    :items="produtosDisponiveis(form.categoria_id)"
                    item-text="nome"
                    item-value="id"
                    item-disabled="disabled"
                    class="d-flex justify-space-between"
                    label="Produto *"
                    clearable
                    dense
                    autocomplete="nope"
                    outlined
                    rounded
                    required
                    :rules="[rules.required]"
                    :disabled="produtos.length === 0"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  sm="6"
                >
                  <v-text-field
                    v-model="form.quantidade"
                    dense
                    label="Quantidade *"
                    outlined
                    :rules="[rules.required]"
                    required
                    type="number"
                    rounded
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                >
                  <base-vuetify-money
                    v-model="form.valor_unitario"
                    :options="money"
                    :rules="[rules.required]"
                    maxlength="10"
                    label="Valor unitário (R$) *"
                    validate-on-blur
                    dense
                    outlined
                    rounded
                  />
                </v-col>
              </v-row>

              <v-row>
                <v-col
                  cols="12"
                >
                  <v-text-field
                    v-model="form.observacao"
                    maxlength="255"
                    height="65"
                    dense
                    label="Observação"
                    outlined
                    rounded
                  />
                </v-col>
              </v-row>
            </v-container>
            <small>*indica campo obrigatório</small>
          </v-card-text>
        </v-form>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="red darken-1 white--text"
            rounded
            @click="cancelar"
          >
            <v-icon
              dark
              left
            >
              mdi-minus-circle
            </v-icon>
            Fechar
          </v-btn>
          <v-btn
            color="green darken-1 white--text"
            rounded
            @click="submitForm"
          >
            <v-icon
              left
              dark
            >
              mdi-content-save
            </v-icon>
            Salvar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
  import { mapActions, mapMutations, mapState } from 'vuex'
  import { BOOTSTRAP_PROFILE, CLOSE, SUBMIT, LOAD_PRODUTOS_UNIDADES, LOAD_UNIDADES } from '../../store/modules/forms/cotacao'
  import rules from '../../utils/formRules'
  export default {
    name: 'CotacaoItenDialog',

    emits: ['input', 'submit'],

    props: {
      value: {
        type: Boolean,
        default: false,
      },

      cotacaoId: {
        type: String,
        default: '',
      },
    },

    data: () => ({
      dialog: false,

      money: {
        locale: 'pt-BR',
        precision: 2,
      },
      rules,

      form: {
        produto_id: '',
        categoria_id: '',
        observacao: '',
        quantidade: '',
        valor_unitario: '',
        valor_subtotal: '',
        valor_total: '',
        cotacao_id: '',
      },
    }),

    computed: {
      ...mapState('form/cotacao', ['produtos', 'produtosUnidades', 'categories', 'unidades', 'loading']),
    },

    watch: {
      value: {
        immediate: true,
        handler (value) {
          this.dialog = value
        },
      },

      dialog (value) {
        this.$emit('input', value)
      },
    },

    created () {
      this.LOAD_PRODUTOS_UNIDADES([this.cotacaoId])
    },

    methods: {
      ...mapMutations('form/cotacao', [CLOSE]),
      ...mapActions('form/cotacao', [BOOTSTRAP_PROFILE, SUBMIT, LOAD_PRODUTOS_UNIDADES, LOAD_UNIDADES]),
      adicionar () {
        this.dialog = true
      },

      cancel () {
        this.CLOSE()
      },

      produtosDisponiveis (nCategoria) {
        const result = this.produtos.filter(el => el.categoria.id === nCategoria)
        return this.produtosUnidades.filter(el => result.find(e => e.id === el.id))
      },

      submitForm () {
        if (!this.$refs.form.validate()) {
          return null
        }

        const produto = this.produtos.find(el => el.id === this.form.produto_id)
        const produto_unidade_negocio_id = this.produtosUnidades.find(el => el.id === this.form.produto_id).produto_unidade_negocio_id

        this.form.nome_produto = produto.nome
        this.form.valor_subtotal = this.form.quantidade * this.form.valor_unitario
        this.form.valor_total = this.form.valor_subtotal
        this.form.unidade_medida = produto.unidade.nome
        this.form.nome_categoria = produto.categoria.nome
        this.form.produto_unidade_negocio_id = produto_unidade_negocio_id
        this.form.produto_id = produto.id

        this.$emit('submit', this.form)
        this.cancelar()
      },

      cancelar () {
        this.form = {
          produto_id: '',
          categoria_id: '',
          observacao: '',
          quantidade: '',
          valor_unitario: '',
          valor_subtotal: '',
          valor_total: '',
          cotacao_id: '',
        }
        this.dialog = false
      },
    },
  }
</script>cotacao,

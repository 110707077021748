import api from '@/api/api'
import { Cotacao } from '@/models/Cotacao'
import rules from '@/utils/formRules'
import Swal from 'sweetalert2'
import { getField, updateField } from 'vuex-map-fields'
import { statusCotacao } from '../../../utils/constants'
import moment from 'moment'
import { v4 as uuid } from 'uuid'

export const BOOTSTRAP_PROFILE = 'BOOTSTRAP_PROFILE'
export const SUBMIT = 'SUBMIT'
export const SET_STATE = 'SET_STATE'
export const CLOSE = 'CLOSE'
export const RESET_STATE = 'RESET_STATE'
export const LOAD_PRODUTOS = 'LOAD_PRODUTOS'
export const LOAD_CATEGORIES = 'LOAD_CATEGORIES'
export const LOAD_UNIDADES = 'LOAD_UNIDADES'
export const PUSH_ITEM = 'PUSH_ITEM'
export const LOAD_PRODUTOS_UNIDADES = 'LOAD_PRODUTOS_UNIDADES'
export const DELETE_ITEM = 'DELETE_ITEM'
export const DELETE_SUBMIT = 'DELETE_SUBMIT'
export const LOAD_PRODUTOS_FROM_CATEGORY = 'LOAD_PRODUTOS_FROM_CATEGORY'
export const CREATE_COTACAO = 'CREATE_COTACAO'

const getDefaultState = () => {
  return {
    dialog: false,
    loading: true,
    rules,
    produtos: [],
    categorias: [],
    unidades: [],
    produtosUnidades: [],
  }
}

const state = getDefaultState()

const getters = {
  getField,
}

const mutations = {
  updateField,
  [RESET_STATE] (state) {
    Object.assign(state, getDefaultState())
  },
  [SET_STATE] (state, payload) {
    Object.assign(state, payload)
  },
  [CLOSE] (state) {
    state.dialog = false
  },
}

const actions = {
  async [BOOTSTRAP_PROFILE] ({ dispatch, commit, state }, { id }) {
    commit(RESET_STATE)
    commit(SET_STATE, { loading: true, dialog: true })
    dispatch('form/BOOTSTRAP_FORM', { model: new Cotacao() }, { root: true })
    await dispatch(LOAD_PRODUTOS)
    await dispatch(LOAD_CATEGORIES)
    if (id) {
      await api.getEntidade('cotacao', id).then(response => {
        response.data.statusPrevious = response.data.status
        response.data.status = ''
        response.data.resposta = null
        response.data.valor_total = response.data.itens.reduce((acc, item) => acc + Number(item.valor_total), 0)
        dispatch('form/BOOTSTRAP_FORM', { model: new Cotacao(response.data) }, { root: true })
      })
    } else {
      const cotacao_id = moment().format('YYMMDDHHMMSS')

      const newCotacao = new Cotacao({
        cotacao_id,
        data_cotacao: new Date().toISOString(),
        valor_total: 0,
        data_prevista_entrega: new Date().toISOString(),
        data_atendimento: new Date().toISOString(),
        itens: [],
        logs: [],
      })

      dispatch('form/BOOTSTRAP_FORM', { model: newCotacao }, { root: true })
    }
    commit(SET_STATE, { loading: false })
  },
  async [SUBMIT] ({ dispatch, commit, state, rootState }) {
    const form = { ...rootState.form.data }

    if (form.status === statusCotacao.cancelamento_solicitado ||
      form.status === statusCotacao.aguardando_analise ||
      form.status === statusCotacao.nao_sincronizado) {
      commit('CLOSE')
      Swal.fire({
        icon: 'warning',
        title: 'Atenção!',
        text: 'Não é possível alterar o Pedido de Cotação para esse status!',
        timer: 2000,
      })
    } else {
      Swal.fire({
        title: 'Editando o Pedido de Cotação!',
        showConfirmButton: false,
        timer: 1500,
      })
      Swal.showLoading()
      api.updateCotacaoStatus(form).then(response => {
        dispatch('cotacoes/ATUALIZAR_COTACAO', { id: form.id, status: form.status }, { root: true })
        commit('CLOSE')
        Swal.hideLoading()
        Swal.update({
          icon: 'success',
          title: 'O Pedido de Cotação foi editado com sucesso!',
        })
      }).catch(err => {
        console.error(err)
        Swal.hideLoading()
        Swal.update({
          icon: 'error',
          title: 'Não foi possível editar o Pedido de Cotação, favor tentar novamente.',
        })
      })
    }
  },

  async [CREATE_COTACAO] ({ dispatch, commit, state, rootState }) {
    const form = { ...rootState.form.data }

    const fazenda = rootState.fazendas.fazendas.find(fazenda => fazenda.id === form.fazenda_id)
    const produtor = rootState.produtores.produtores.find(produtor => produtor.id === form.produtor_id)
    const cadastro = fazenda.produtores.find(produtor => produtor.id === form.produtor_id).cadastro
    const user = rootState.user

    const id = uuid()
    const formResponse = {
      id: id,
      unidade_negocio_id: form.unidade_negocio_id,
      data_cotacao: moment(form.data_cotacao).format('YYYY-MM-DD HH:mm:ss'),
      cotacao_id: form.cotacao_id,
      user_id: user.id,
      nome_produtor: produtor.nome_razao_social,
      produtor_id: form.produtor_id,
      tecnico_id: cadastro.tecnico_id,
      status: form.status,
      observacao: form.observacao,
      resposta: form.resposta ?? null,
      fazenda_id: form.fazenda_id,
      cpf_cnpj_produtor: produtor.cpf_cnpj,
      nome_fazenda: fazenda.razao_social,
      inc_estadual_fazenda: fazenda.inscricao_estadual,
      bairro_fazenda: fazenda.endereco.bairro,
      cidade_fazenda: fazenda.endereco.cidade.nome,
      estado_fazenda: fazenda.endereco.estado.nome,
      latitude: fazenda.latitude,
      longitude: fazenda.longitude,
      cod_produtor_fornecedor: cadastro.cod_produtor_fornecedor,
      cod_produtor_cliente: cadastro.cod_produtor_cliente,
      fazenda_produtor_id: cadastro.id,
      parcelas: form.parcelas,
      tipo_atendimento: form.tipo_atendimento,
      data_prevista_entrega: moment(form.data_prevista_entrega).format('YYYY-MM-DD HH:mm:ss'),
      data_limite: form.data_limite,
      data_atendimento: form.data_atendimento ? moment(form.data_atendimento).format('YYYY-MM-DD HH:mm:ss') : null,
      observacao_atendimento: form.observacao_atendimento,
      items: form.itens.map(item => ({
        ...item,
        id: uuid(),
        cotacao_id: id,
      })),
    }

    if (form.status === statusCotacao.cancelamento_solicitado ||
      form.status === statusCotacao.aguardando_analise ||
      form.status === statusCotacao.nao_sincronizado) {
      commit('CLOSE')
      Swal.fire({
        icon: 'warning',
        title: 'Atenção!',
        text: 'Não é possível alterar o Pedido de Cotação para esse status!',
        timer: 2000,
      })
    } else {
      api.cadastrarEntidade(formResponse, 'cotacao').then(response => {
        let data_i = new Date()
        data_i.setMonth(data_i.getMonth() - 3)
        data_i = data_i.toISOString().slice(0, 10)
        const data_f = new Date().toISOString().slice(0, 10)
        this.data_inicial = data_i
        this.data_final = data_f

        dispatch('cotacoes/BOOTSTRAP', { data_inicial: data_i, data_final: data_f }, { root: true })
        commit('CLOSE')
        Swal.fire({
          icon: 'success',
          title: 'O Pedido de Cotação foi criado com sucesso!',
          timer: 4000,
        })
      }).catch(err => {
        Swal.fire({
          icon: 'error',
          title: 'Não foi possível criar o Pedido de Cotação, favor tentar novamente.',
          text: `Caso o erro persista, favor entrar em contato com o suporte: ${err.message}`,
          timer: 5000,
        })
      })
    }
  },

  async [LOAD_PRODUTOS] ({ commit }) {
    await api.listEntidade('produto').then((produtos) => {
      commit(SET_STATE, { produtos })
    })
  },

  async [LOAD_PRODUTOS_UNIDADES] ({ commit }, unidades) {
    await api.getProdutosUnidades(unidades).then((produtosUnidades) => {
      commit(SET_STATE, { produtosUnidades })
    })
  },

  async [LOAD_CATEGORIES] ({ commit }) {
    await api.listEntidade('categoria').then((categories) => {
      commit(SET_STATE, { categories })
    })
  },

  async [LOAD_UNIDADES] ({ commit }) {
    await api.listEntidade('unidade').then((unidades) => {
      commit(SET_STATE, { unidades })
    })
  },

  async [LOAD_PRODUTOS_FROM_CATEGORY] ({ commit }, category) {
    await api.listEntidade(`categoria/${category}`).then((produtos) => {
      commit(SET_STATE, { produtos })
    })
  },

  async [PUSH_ITEM] ({ commit, state, rootState }, item) {
    commit(SET_STATE, { loading: true, dialog: true })
    const form = { ...rootState.form.data }
    form.itens.push({
      ...item,
      tmp_id: uuid(),
    })
    commit('form/SET_STATE', { data: form }, { root: true })
    commit(SET_STATE, { loading: false })
  },

  async [DELETE_ITEM] ({ commit, state, rootState }, item) {
    commit(SET_STATE, { dialog: true })
    const form = { ...rootState.form.data }

    return await Swal.fire({
      title: 'Excluir',
      text: 'Tem certeza que deseja excluir o item da cotação?',
      icon: 'question',
      showCancelButton: true,
      showLoaderOnConfirm: true,
      confirmButtonColor: '#109010',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Excluir',
      cancelButtonText: 'Cancelar',
      reverseButtons: true,
      focusConfirm: false,
      preConfirm: () => {
        if (!item.id) {
          commit(SET_STATE, { loading: false })
          return
        }

        return api.inativarEntidade('cotacao/item', item.id).then(
          result => result,
          () => {
            window.Toast.fire('Ocorreu algum erro na exclusão do item da cotação!', '', 'error')
          },
        ).catch(() => {
          window.Toast.fire('Ocorreu algum erro na exclusão do item da cotação!', '', 'error')
        })
      },
    }).then((result) => {
      if (result.isConfirmed) {
        window.Toast.fire('O item da cotação foi excluída com sucesso!', '', 'success')
        form.itens.splice(form.itens.indexOf(item), 1)
      }
    })
  },

  async [DELETE_SUBMIT] ({ dispatch, commit, state, rootState }) {
    const form = { ...rootState.form.data }

    return Swal.fire({
      title: 'Excluir',
      text: 'Tem certeza que realmente deseja excluir está cotação?',
      icon: 'question',
      showCancelButton: true,
      showLoaderOnConfirm: true,
      confirmButtonColor: '#109010',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Excluir',
      cancelButtonText: 'Cancelar',
      reverseButtons: true,
      focusConfirm: false,
      preConfirm: () => {
        return api.inativarEntidade('cotacao', form.id).then(
          result => result,
          () => {
            window.Toast.fire('Ocorreu algum erro na exclusão da Cotação!', '', 'error')
          },
        ).catch(() => {
          window.Toast.fire('Ocorreu algum erro na exclusão da Cotação!', '', 'error')
        })
      },
    }).then((result) => {
      if (result.isConfirmed) {
        window.Toast.fire('A cotação foi excluída com sucesso!', '', 'success')
        dispatch('cotacoes/BOOTSTRAP', { data_inicial: 'from', data_final: 'to' }, { root: true })
        commit('CLOSE')
      }
    })
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
}

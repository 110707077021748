<template>
  <v-dialog
    v-model="dialog"
    max-width="1320px"
    max-height="800px"
    width="800px"
    scrollable
    persistent
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <v-card>
      <v-toolbar
        color="primary"
        dark
      >
        <v-col
          cols="11.5"
        >
          <v-toolbar-title
            v-if="loading"
          >
            {{ 'Histórico das Cotações' }}
          </v-toolbar-title>
          <v-toolbar-title
            v-else
          >
            {{ 'Histórico da Cotação Nº ' + numero_cotacao }}
          </v-toolbar-title>
        </v-col>
        <v-btn
          icon
          dark
          @click="cancel"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <base-loading v-if="loading" />
      <v-card-text
        v-else
        class="px-5"
      >
        <v-card>
          <v-col>
            <v-row>
              <v-col
                cols="6"
              >
                <v-text-field
                  v-model="nome_produtor"
                  label="Produtor"
                  dense
                  readonly
                  outlined
                  rounded
                />
              </v-col>
              <v-col
                cols="6"
              >
                <v-text-field
                  v-model="nome_fazenda"
                  label="Fazenda"
                  dense
                  readonly
                  outlined
                  rounded
                />
              </v-col>
            </v-row>
          </v-col>
        </v-card>
        <v-card>
          <crud-list
            v-model="logs"
            :headers="headers"
            :sort-by="['data']"
            :sort-desc="[true]"
            :slots="[
              'item.data',
            ]"
            :item-height="$vuetify.breakpoint.xl ? '300' : '200'"
          >
            <template v-slot:[`item.data`]="{ item }">
              <span>{{ datetimeFormatter(item.data) }}</span>
            </template>
          </crud-list>
        </v-card>
      </v-card-text>
      <v-card-actions
        class="justify-end"
      >
        <v-btn
          class="text-none text-white"
          color="error"
          rounded
          @click="cancel"
        >
          <v-icon
            dark
            left
          >
            mdi-minus-circle
          </v-icon>
          Cancelar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
  import { mapMutations, mapState } from 'vuex'
  import { CLOSE, SET_STATE } from '@/store/modules/cotacaologs'
  import { datetimeFormatter } from '../../utils/formatter'
  import { sortDateTime } from '../../utils/sorts'

  export default {
    name: 'CotacaoLogs',
    components: {
    },
    data () {
      return {
        headers: [
          { align: 'left', sortable: true, text: 'Status', value: 'status', width: 'auto' },
          { align: 'left', sortable: true, text: 'Usuário', value: 'user_name', width: 'auto' },
          { align: 'left', sortable: true, text: 'Data', value: 'data', width: 'auto', sort: sortDateTime },
          { align: 'left', sortable: true, text: 'Observação', value: 'log', width: 'auto' },
        ],
        datetimeFormatter,
      }
    },
    computed: {
      ...mapState('cotacaologs', ['ready', 'loading', 'rules', 'dialog', 'logs', 'numero_cotacao', 'nome_produtor', 'nome_fazenda']),
    },
    methods: {
      ...mapMutations('cotacaologs', [CLOSE, SET_STATE]),
      cancel () {
        this.CLOSE()
      },
    },
  }
</script>

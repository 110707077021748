import { v4 } from 'uuid'
import { CotacaoLog } from './CotacaoLog'

export class Cotacao {
  constructor ({
    id,
    cotacao_id,
    unidade_negocio_id,
    user_id,
    nome_produtor,
    nome_fazenda,
    statusPrevious,
    status,
    data_cotacao,
    observacao,
    resposta,
    itens,
    logs,
    valor_total,
    parcelas,
    fazenda_id,
    produto_id,
    data_limite,
    data_prevista_entrega,
    tipo_atendimento,
    data_atendimento,
    observacao_atendimento,
    signature_id,

  } = {
    id: v4(),
    cotacao_id: '',
    user_id: 0,
    unidade_negocio_id: '',
    nome_produtor: '',
    nome_fazenda: '',
    statusPrevious: '',
    status: '',
    data_cotacao: '',
    observacao: '',
    resposta: '',
    valor_total: 0,
    parcelas: 0,
    fazenda_id: '',
    produto_id: '',
    data_limite: '',
    data_prevista_entrega: '',
    tipo_atendimento: '',
    data_atendimento: '',
    observacao_atendimento: '',
    signature_id: '',
    itens: [],
    logs: [],
  }) {
    this.id = id
    this.cotacao_id = cotacao_id
    this.unidade_negocio_id = unidade_negocio_id
    this.user_id = user_id
    this.nome_produtor = nome_produtor
    this.nome_fazenda = nome_fazenda
    this.statusPrevious = statusPrevious
    this.status = status
    this.data_cotacao = data_cotacao
    this.observacao = observacao
    this.resposta = resposta
    this.valor_total = valor_total
    this.parcelas = parcelas
    this.fazenda_id = fazenda_id
    this.produto_id = produto_id
    this.data_limite = data_limite
    this.data_prevista_entrega = data_prevista_entrega
    this.tipo_atendimento = tipo_atendimento
    this.data_atendimento = data_atendimento
    this.observacao_atendimento = observacao_atendimento
    this.signature_id = signature_id
    this.itens = itens.map(({ ...item }) => {
      return {
        ...item,
      }
    })
    this.logs = logs.map(({ ...rest }) => new CotacaoLog(({
      ...rest,
    })))
  }
}
